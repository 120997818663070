import React from 'react'

const NotFound = () => {
  return (
    <div style={{display:"flex",justifyContent:"center"}}>
      <img alt='404' src='404.png'/>
    </div>
  )
}

export default NotFound
