import { apiSlice } from "./apiSlice"
export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addOrder:builder.mutation({
            query: formData => ({
                url: '/orders',
                method: 'POST',
                body:formData
            }),
        }),
    })
})

export const {
useAddOrderMutation
} = orderApiSlice 