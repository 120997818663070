import React, { useEffect, useState } from 'react'
import Basket from './Basket'
import { useSelector } from 'react-redux'
import Modal from "./Modal"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetProductsMutation } from '../api/productApiSlice'
const Product = () => {
  const [getProducts] = useGetProductsMutation()
  //toastify
  const notify = (error) => toast(error);
  const branchId = useSelector(state=>state.branch.branch.branchId)
  const basketItems = useSelector(state=>state.cart.products)
  const cardBranchId = useSelector(state=>state.cart.products.length > 0 ? state.cart.products[0].branchId : 0)
  const [products,setProducts] = useState([])

  const [showModal,setShowModal] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleClose = ()=>{
    setShowModal(false)
    const modal = document.getElementsByClassName("modal")
    modal[0].classList.remove('open')
    modal[0].classList.add('closed')
  }

  const handleOpen = (product)=>{
    setShowModal(true)
    setCurrentProduct(product)
    const modal = document.getElementsByClassName("modal");
    if(modal.length > 0){
      modal[0].classList.add('open');
    }
  }

    const handleWarning = () =>{
      notify("You have items from different branches in your basket.")
    }
  useEffect(()=>{
   
    const fetchProducts = async() =>{
      try {
        const res = await getProducts({branchId})
        setProducts(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProducts()
  },[branchId])

  const itemsBySubcategory = {}; 
  products.forEach(item => {
    const subcategory = item.subcategoryName;
    if (!itemsBySubcategory[subcategory]) {
      itemsBySubcategory[subcategory] = []; 
    }
  
    // Check if the item already exists in the subcategory
    const existingItem = itemsBySubcategory[subcategory].find(i => i.productId === item.productId);
    if (existingItem) {
      // Add the variant to the existing item
      existingItem.variants.push({
        variantGroupName: item.variantGroupName,
        variantId : item.variantId,
        variantName: item.variantName,
        variantPrice: item.variantPrice,
        variantNote: item.note
      
      });
    } else {
      // Create a new item with the variants array
      const newItem = {
        id: item.id,
        productId: item.productId,
        productName: item.productName,
        image: item.image,
        description: item.description,
        taxPercent: item.taxPercent,
        price: item.price,
        discount: item.discount,
        deposit: item.deposit,
        categoryId: item.categoryId,
        subcategoryId: item.subcategoryId,
        pieceOrKilo: item.pieceOrKilo,
        isActive: item.isActive,
        branchId: item.branchId,
        variants: [item.variantId !== null ? {
          variantGroupName: item.variantGroupName,
          variantId : item.variantId,
          variantGroupId:item.variantGroupId,
          variantName: item.variantName,
          variantPrice: item.variantPrice,
          variantNote: item.note
        } : null]
      };
      itemsBySubcategory[subcategory].push(newItem);
    }
  });
  

  return (

    <div className="product-container">
    {showModal && <Modal handleClose={handleClose} product={currentProduct}/>}
      <div className="container">
      {basketItems.length > 0 ? <Basket type="branch"/> : <div></div>}
      <div className={`product-box ${basketItems.length <= 0 ? "full" : ""}`}>

          <div className="product-minimal">

            <div className="product-showcase">
            
              {Object.keys(itemsBySubcategory).map(subcategory => (
                   <div key={subcategory}>
                   <h2 className="title">{subcategory}</h2>
                  { itemsBySubcategory[subcategory].map(product => (
              
                        <div className="showcase-container" onClick={() => {
                          cardBranchId === branchId  || cardBranchId === 0 ?
                         handleOpen(product) : handleWarning()// toggle state variable
                        }} key={product.id}>
                      
        <div className="showcase" >

        <div className="showcase-img-box">
          <img src={process.env.REACT_APP_API_URL+`images/${product.image}`} alt={product.productName} width="70" className="showcase-img"/>
        </div>

        <div className="showcase-content">

          <div>
            <h4 className="showcase-title">{product.productName}</h4>
          </div>

          <div href="#" className="showcase-subcategory">{product.subcategoryName}</div>
          <div href="#" className="showcase-description">{product.description}</div>

          <div className="price-box">
            
          
        {product.discount !== 0 && <del>{Math.floor(( product.price * 1/product.discount) + product.price)} €</del>}
        <p className="price">{product.price} €</p>
          </div>

        </div>
        <button onClick={() => {
                          cardBranchId === branchId  || cardBranchId === 0 ?
                         handleOpen(product) : handleWarning()// toggle state variable
                        }}>
        
 
      <ion-icon name="add-outline" className="add-icon"></ion-icon>
        </button> 

        </div>
        </div>
                  
                ))}
                 
        </div>
              ))}      
                
            </div>

          </div> 

        </div>

      </div>
      <ToastContainer />
    </div>
  )
}

export default Product
