
import React from 'react'
import { Link } from "react-router-dom";
import Searchbar from './Searchbar';
import { useSelector } from 'react-redux';

const Header = ({type}) => {
  const branchName = useSelector(state=>state.branch.branch.branchName)
  const basketItems = useSelector(state=>state.cart.products)
  const mobileMenuCloseFunc = ()=>{
    const mobileMenu =  document.getElementsByClassName("mobile-navigation-menu")
    mobileMenu[0].classList.remove('active');
  }

  const mobileMenuOpenFunc = ()=>{
    const mobileMenu =  document.getElementsByClassName("mobile-navigation-menu")
    mobileMenu[0].classList.add('active');
  }

  // const basketItems = useSelector(state=>state.cart.products)
  const authUser = useSelector(state=>state.auth.user)
  return (
    <header>

    <div className="header-top">

      <div className="container">

        <ul className="header-social-container">

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

        </ul>

        {/* <div className="header-alert-news">
          <p>
            <b>Free Shipping</b>
            This Week Order Over - $55
          </p>
        </div> */}

        {/* <div className="header-top-actions">

          <select name="language">

            <option value="en-US">English</option>
            <option value="es-ES">Deutsch</option>
   

          </select>

        </div> */}

      </div>

    </div>

    <div className="header-main">

      <div className="container">

      {type === "home" && <Link to="/" className="header-logo"> 
          <div>ElbeOrder</div>
          {/* <img src="./assets/images/logo/logo.svg" alt="Anon's logo" width="120" height="36"/> */}
        </Link> 
      }
      {type !== "home" &&
        <Link to="/" className="header-logo">
          <div>{"< "}ElbeOrder </div>
          </Link>
        }
        {type === "branch" &&
          <div style={{color:"black",fontWeight:"bold",display:"flex" ,justifyContent:"center"}}>
            <span>{branchName}</span>
          </div>   
        }
        {type === "home" &&  <Searchbar /> }

        <div className="header-user-actions">
          <Link to="/bezahlen" className="action-btn">
            <ion-icon name="bag-handle-outline"></ion-icon>
            <span className="count">{basketItems.length}</span>
          </Link>
          <Link to={authUser ? "/profile" : "/login"} className="action-btn">
            <ion-icon name="person-outline"></ion-icon>
          </Link>

        </div>

      </div>

    </div>

    {type === "home" && <nav className="desktop-navigation-menu">

      <div className="container">

        <ul className="desktop-menu-category-list">

          {/* <li className="menu-category">
            <a href="#" className="menu-title">Home</a>
          </li> */}
{/* 
          <li className="menu-category">
            <a href="#" className="menu-title">Home</a>

            <ul className="dropdown-list">

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

            </ul>
          </li>

          <li className="menu-category">
            <a href="#" className="menu-title">Home</a>

            <ul className="dropdown-list">

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

              <li className="dropdown-item">
                <a href="#">Home</a>
              </li>

            </ul>
          </li> */}


          <li className="menu-category">
            <Link to="/" className="menu-title">Home</Link>
          </li>

          <li className="menu-category">
            <Link to="/aboutus" className="menu-title">About Us</Link>
          </li>

          <li className="menu-category">
            <Link to="/contact" className="menu-title">Contact</Link>
          </li>
        </ul>

      </div>

    </nav> }

    <div className="mobile-bottom-navigation">

      <button onClick={mobileMenuOpenFunc} className="action-btn" data-mobile-menu-open-btn>
        <ion-icon name="menu-outline"></ion-icon>
      </button>

      <Link to="/" className="action-btn">
        <ion-icon name="home-outline"></ion-icon>
      </Link>

      <Link to="/bezahlen" className="action-btn">
        <ion-icon name="bag-handle-outline"></ion-icon>
        <span className="count">{basketItems.length}</span>
      </Link>

      <Link to="/login" className="action-btn">
        <ion-icon name="person-outline"></ion-icon>
      </Link>
    </div>

    <nav className="mobile-navigation-menu  has-scrollbar">

      <div className="menu-top">
        <h2 className="menu-title">Menu</h2>

        <button onClick={mobileMenuCloseFunc} className="menu-close-btn" >
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>

      <ul className="mobile-menu-category-list">

        <li className="menu-category">
          <a href="#" className="menu-title">Home</a>
        </li>

        {/* <li className="menu-category">

          <button className="accordion-menu" data-accordion-btn>
            <p className="menu-title">Home</p>

            <div>
              <ion-icon name="add-outline" className="add-icon"></ion-icon>
              <ion-icon name="remove-outline" className="remove-icon"></ion-icon>
            </div>
          </button>

          <ul className="submenu-category-list" data-accordion>

            <li className="submenu-category">
              <a href="#" className="submenu-title">Home</a>
            </li>

            <li className="submenu-category">
              <a href="#" className="submenu-title">Home</a>
            </li>

            <li className="submenu-category">
              <a href="#" className="submenu-title">Home</a>
            </li>

            <li className="submenu-category">
              <a href="#" className="submenu-title">Home</a>
            </li>

          </ul>

        </li>
        <li className="menu-category">

<button className="accordion-menu" data-accordion-btn>
  <p className="menu-title">Home</p>

  <div>
    <ion-icon name="add-outline" className="add-icon"></ion-icon>
    <ion-icon name="remove-outline" className="remove-icon"></ion-icon>
  </div>
</button>

<ul className="submenu-category-list" data-accordion>

  <li className="submenu-category">
    <a href="#" className="submenu-title">Home</a>
  </li>

  <li className="submenu-category">
    <a href="#" className="submenu-title">Home</a>
  </li>

  <li className="submenu-category">
    <a href="#" className="submenu-title">Home</a>
  </li>

  <li className="submenu-category">
    <a href="#" className="submenu-title">Home</a>
  </li>

</ul>

      </li> */}
  
        <li className="menu-category">
          <a href="#" className="menu-title">About Us</a>
        </li>
{/* 
        <li className="menu-category">
          <a href="#" className="menu-title">Home</a>
        </li> */}

      </ul>

      <div className="menu-bottom">

        {/* <ul className="menu-category-list">

          <li className="menu-category">

            <button className="accordion-menu" data-accordion-btn>
              <p className="menu-title">Language</p>

              <ion-icon name="caret-back-outline" className="caret-back"></ion-icon>
            </button>

            <ul className="submenu-category-list" data-accordion>

              <li className="submenu-category">
                <a href="#" className="submenu-title">English</a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">Deutsch</a>
              </li>

        

            </ul>

          </li>


        </ul> */}

        <ul className="menu-social-container">

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" className="social-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

        </ul>

      </div>

    </nav>

  </header>
  )
}

export default Header
