import { apiSlice } from "./apiSlice"
export const paymentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        payment:builder.mutation({
            query: formData => ({
                url: '/payment',
                method: 'POST',
                body:formData
            }),
        }),
    })
})

export const {
usePaymentMutation
} = paymentApiSlice 