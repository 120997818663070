import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeBranch } from '../redux/branchReducer';
import { useGetAllPostCodesMutation } from '../api/postcodeApiSlice';

const Searchbar = () => {
    const [search,setSearch] = useState("")
    const [result,setResult] = useState([])
    const dispatch = useDispatch();
    const [getAllPostCodes] = useGetAllPostCodesMutation()
    const fetchPostCode = async(value)=>{
      try {
        const res = await getAllPostCodes()
        const filteredResults = res.data.filter((postCode)=>{
           return postCode.postCode.toString().includes(value)
         })
        setResult(filteredResults)
      } catch (error) {
        console.log(error)
      }
    }
    
    
  
    const handleChange = (value)=>{
      setSearch(value)
      fetchPostCode(value)
    }

    useEffect(() => {
      return () => {
          setSearch("");
      };
  }, [])
  return (
    <div className="header-search-container">
        <div className='search-box'>
            <input type="number" name="search" value={search} className="search-field" placeholder="Geben Sie Ihre Postnummer ein..." onChange={(e)=>handleChange(e.target.value)}/>
            <button className="search-btn">
            <ion-icon name="search-outline"></ion-icon>
            </button>
        </div>
        {
        search !== "" &&
        
        <div className='result-box has-scrollbar'>
            {result.map((each)=>{
                 
                return <Link
                 key={each.id}
                 className="link"
                 onClick={() => dispatch(changeBranch({branchId:each.pbActive === 1 ? each.pbId : each.sbId,branchName:each.pbActive === 1 ? each.pbName : each.sbName,postCode:each.postCode,minOrder:each.minOrder,deliveryFee:each.deliveryFee}))}
                 to={each.pbActive === 1 ? `/${each.pbName}` : `/${each.sbName}`}
          
             >
                 {each.pbName}, {each.regionName}, {each.postCode}</Link>
            })}
        </div> 
        }
    </div>

  )
}

export default Searchbar
