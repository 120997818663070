import React from 'react'

const Banner = () => {
  return (
    <div className="banner">

      <div className="container">

        <div className="slider-container has-scrollbar">

          <div className="slider-item">

            <img src="/images/banner-1.png" alt="banner1" className="banner-img"/>

            <div className="banner-content">

              <p className="banner-subtitle">Berlin Vegan Cigkoefte</p>

              <h2 className="banner-title">MEHR KAUFEN</h2>
              <h2 className="banner-title">WENIGER BEZAHLEN</h2>
           

              </div>
          </div>

          <div className="slider-item">

            <img src="/images/banner-2.png" alt="banner2" className="banner-img"/>

            <div className="banner-content">

              <p className="banner-subtitle">Berlin Vegan Cigkoefte</p>

              <h2 className="banner-title">Es ist jetzt einfacher</h2>

            </div>

          </div>

    

        </div>

      </div>

    </div>
  )
}

export default Banner
