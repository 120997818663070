import { apiSlice } from "./apiSlice"
export const productApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.mutation({
            query: ({branchId}) => ({
                url: `/products/branches/active/${branchId}`,
                method: 'GET',
            }),
        }),
})
})
export const {
useGetProductsMutation
} = productApiSlice 