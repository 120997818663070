import Footer from "../Footer";
import Header from "../Header";

function Completion(props) {
    return (
    <div>
    <Header type="home"/>
    <h1 style={{display:"flex",justifyContent:"center"}}>We have received your order! Thank you! 🎉</h1>
    <Footer/>
    </div>
    )
    
  }
  
  export default Completion;