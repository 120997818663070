import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AboutBanner from '../components/AboutBanner'

const AboutUs = () => {
  return (
    <div>
    <Header type="home"/>
    <AboutBanner/>
    <Footer/>
    </div>
  )
}

export default AboutUs
