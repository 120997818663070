import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetSubcategoriesMutation } from '../api/subcategoryApiSlice'

const Category = () => {
  const [categories,setCategories] = useState([])
  const [getSubcategories] = useGetSubcategoriesMutation()
  const branchId = useSelector(state=>state.branch.branch.branchId)
  useEffect(()=>{
    const fetchProducts = async() =>{
      try {
        const res = await getSubcategories(branchId)
        setCategories(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProducts()
  },[branchId])
  return (
    <div className="category">

      <div className="container">

        <div className="category-item-container has-scrollbar">
        {
          categories.map(category=>(
            <div className="category-item" key={category.id}>

            <div className="category-content-box">

              <div className="category-content-flex">
                <h3 className="category-item-title">{category.subcategoryName}</h3>

                <p className="category-item-amount">({category.amount})</p>
              </div>

              <a href="#" className="category-btn">Show all</a>

            </div>

          </div>

          ))
        }
          {/* <div className="category-item">

            <div className="category-img-box">
              <img src="./assets/images/icons/dress.svg" alt="dress & frock" width="30"/>
            </div>

            <div className="category-content-box">

              <div className="category-content-flex">
                <h3 className="category-item-title">Dress & frock</h3>

                <p className="category-item-amount">(53)</p>
              </div>

              <a href="#" className="category-btn">Show all</a>

            </div>

          </div> */}


        </div>

      </div>

    </div>
  )
}

export default Category
