import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Banner2 from '../components/Banner2'


const Home = () => {
  return (
    <div>
    <Header type="home"/>
    <Banner/>
    <Banner2/>
    <Footer/>
    </div>

  )
}

export default Home
