import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React from 'react'
import "./stripe.css"
import { clearCard } from "../../redux/cartReducer"
import { useNavigate, } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePaymentMutation } from "../../api/paymentApSlice"
const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#ggg",
			color: "#ggg",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#ggg" },
			"::placeholder": { color: "#ggg" }
		},
		invalid: {
			iconColor: "#ggg",
			color: "#ggg"
		}
	}
}

export default function PaymentForm() {

    const notify = (error) => toast(error);
    const stripe = useStripe()
    const elements = useElements()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const deliveryFee = useSelector(state=>state.cart.deliveryFee)
    const total = useSelector(state=>state.cart.total)
    const [payment] = usePaymentMutation()
    const handleSubmit = async (e) => {
        e.preventDefault()
       
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })

    if(!error) {
        try {
            const {id} = paymentMethod
            await payment({
                amount: total + deliveryFee,
                id
            })
            // const {id} = paymentMethod
            // const response = await axios.post(process.env.REACT_APP_API_URL+"payment", {
            //     amount: total + deliveryFee,
            //     id
            // }  ,{headers:{
            //     "Authorization":`Bearer ${process.env.REACT_APP_API_TOKEN}`
            //   }})
            
            // if(response.data.success) {
              notify("Order completed")
              dispatch(clearCard())
              navigate('/bezahlen/completion',{ replace: true });
            // }else{
            //     notify("Order failed!")
            // }

        } catch (error) {
            notify(error)
        }
    } else {
        notify(error.message)
    }
}

    return (
    <>
        <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            <button>Pay</button>
        </form>
        <ToastContainer/>
    </>
    )
}