import { createSlice } from '@reduxjs/toolkit'


export const branchSlice = createSlice({
  name: 'branch',
  initialState: {
   branch: {}
  },
  reducers: {
    changeBranch: (state,action) => {
        state.branch = action.payload
  }
  
  }
})

// Action creators are generated for each case reducer function
export const {changeBranch } = branchSlice.actions

export default branchSlice.reducer