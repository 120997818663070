import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useDispatch } from 'react-redux'
import { loginStart, loginSuccess } from '../redux/authReducer'
import axios from 'axios'
import "./register.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
  const notify = (error) => toast(error);
  const dispatch = useDispatch()
  const [loginForm,setLoginForm] = useState()
  const handleChange = (e) => {
    setLoginForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleLogin = async(e) => {
      e.preventDefault();
      if (loginForm.email && loginForm.password) {
       await axios.post(process.env.REACT_APP_API_URL+'customerauth', {
          email: loginForm.email,
          password: loginForm.password
        }   ,{headers:{
          "Authorization":`Bearer ${process.env.REACT_APP_API_TOKEN}`
        }})
          .then(response => {
            if (response.data.message === "success") {
                    dispatch(loginSuccess({
                      user:JSON.stringify(response.data.data),
                      isFetching:true,
                      error:false
            }))
             
            } else {
              notify(response.data.message);
            }
          })
          .catch(error => {
        
            notify("An error occurred during login.");
          });
      } else {
        notify("Please enter username and password");
      }
  }
  
  return (
    <div>
    <Header/>
    <form className='login-form' onSubmit={handleLogin}>
      <div className="formInput">
                  <label>Email</label>
                  <input name="email" onChange={handleChange} type="email" placeholder="Type email" />
        </div>
        <div className="formInput">
                  <label>Password</label>
                  <input name="password" onChange={handleChange} type="password" placeholder="Type password" />
        </div>
    
      <button  className="pay-button">Login</button>
      <Link className='register' to={"/register"}>
      <span>Go to Register</span>
      </Link>
    </form>
    <ToastContainer />
    <Footer/>
    </div>
   
  )
}

export default Login
