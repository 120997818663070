import { apiSlice } from "./apiSlice"
export const subcategoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubcategories: builder.mutation({
            query: branchId => ({
                url: `/subcategories/${branchId}`,
                method: 'GET',
            }),
        }),
       
})
})
export const {
useGetSubcategoriesMutation
} = subcategoryApiSlice 