import { createSlice } from '@reduxjs/toolkit'


export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products: [],
    total:0,
    minOrder:0,
    deliveryFee:0
  },  
  
  reducers: {
    
    addToCard: (state, action) => {
      function areArraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }
      
        for (let i = 0; i < arr1.length; i++) {
          const obj1 = arr1[i];
          const obj2 = arr2[i];
      
          if (
            obj1.variantId !== obj2.variantId
          ) {
            return false;
          }
        }
      
        return true;
      }
      

      const { id, variants } = action.payload;
      const existingItem = state.products.find(item => 
        item.id === id && 
        areArraysEqual(variants, item.variants)
      );
      if (existingItem) {
          existingItem.quantity += 1;
          existingItem.variants.forEach((variantItem) => {
            const matchingVariant = variants.find((variant) => variant.variantId === variantItem.variantId);
            if (matchingVariant) {
              variantItem.variantAmount += 1;
            }
          });
          const totalPriceOfVariants = variants.reduce((total, variant) => total + variant.variantPrice, 0);
          state.total += existingItem.price +totalPriceOfVariants;
      } else {
        const newItem = { ...action.payload};
        const totalPriceOfVariants = variants.reduce((total, variant) => total + variant.variantPrice, 0);
        state.products.push(newItem);
        state.total += newItem.price + totalPriceOfVariants;
        state.minOrder = newItem.minOrder;
        state.deliveryFee = newItem.deliveryFee;
      }
    },
    
    removeFromCard: (state, action) => {
      function areArraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }
      
        for (let i = 0; i < arr1.length; i++) {
          const obj1 = arr1[i];
          const obj2 = arr2[i];
      
          if (
            obj1.variantId !== obj2.variantId
          ) {
            return false;
          }
        }
      
        return true;
      }
      
      const { id, variants } = action.payload;
      const existingItem = state.products.find(item => 
        item.id === id && 
        areArraysEqual(variants, item.variants)
      );
      
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
        existingItem.variants.forEach((variantItem) => {
          const matchingVariant = variants.find((variant) => variant.variantId === variantItem.variantId);
          if (matchingVariant) {
            variantItem.variantAmount -= 1;
          }
        });
        const totalPriceOfVariants = existingItem.variants.reduce((total, variant) => total - variant.variantPrice, 0);
        state.total -= existingItem.price - totalPriceOfVariants;
      } else {
        const totalPriceOfVariants = existingItem.variants.reduce((total, variant) => total - variant.variantPrice * variant.variantAmount, 0);
        state.total -= existingItem.price - totalPriceOfVariants;
        state.products = state.products.filter(item => !areArraysEqual(variants, item.variants));
      }
    },
    
            // Add a variant to an existing product in the cart
            addVariantToCard: (state, action) => {
              function areArraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) {
                  return false;
                }
              
                for (let i = 0; i < arr1.length; i++) {
                  const obj1 = arr1[i];
                  const obj2 = arr2[i];
              
                  if (
                    obj1.variantId !== obj2.variantId
                  ) {
                    return false;
                  }
                }
              
                return true;
              }
              const { id, variantId,variants } = action.payload;
              const existingItem = state.products.find(item =>
                item.id === id && 
                areArraysEqual(variants, item.variants)
              );
            
              if (existingItem) {
                const existingVariant = existingItem.variants.find(variant =>
                  variant.variantId === variantId
                );
            
                if (existingVariant) {
                  existingVariant.variantAmount += 1;
                  state.total += existingVariant.variantPrice;
                }
              }
            },
            
      
            removeVariantFromCard: (state, action) => {
              function areArraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) {
                  return false;
                }
              
                for (let i = 0; i < arr1.length; i++) {
                  const obj1 = arr1[i];
                  const obj2 = arr2[i];
              
                  if (
                    obj1.variantId !== obj2.variantId
                  ) {
                    return false;
                  }
                }
              
                return true;
              }
              
              const { id, variantId, variants } = action.payload;
              const existingItem = state.products.find(item =>
                item.id === id && 
                areArraysEqual(variants, item.variants)
              );
              
              if (existingItem) {
                const existingVariant = existingItem.variants.find(variant =>
                  variant.variantId === variantId
                );
              
                if (existingVariant) {
                  if (existingVariant.variantAmount > 1) {
                    existingVariant.variantAmount -= 1;
                  } else {
                    existingItem.variants = existingItem.variants.filter(variant =>
                      variant.variantId !== variantId
                    );
                  }
                  state.total -= existingVariant.variantPrice;
                }
              }
            },
            
      
    clearCard: (state, action) => {
      state.products = []
      state.total = 0
      state.minOrder = 0
      state.deliveryFee = 0
    }
  }
})

// Action creators are generated for each case reducer function
export const { addToCard, removeFromCard, clearCard,addVariantToCard,removeVariantFromCard } = cartSlice.actions

export default cartSlice.reducer