import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCard } from '../redux/cartReducer';

const Modal = ({ handleClose, product }) => {
  const branchId = useSelector((state) => state.branch.branch.branchId);
  const branchName = useSelector((state) => state.branch.branch.branchName);
  const postCode = useSelector((state) => state.branch.branch.postCode);
  const deliveryFee = useSelector((state) => state.branch.branch.deliveryFee);
  const minOrder = useSelector((state) => state.branch.branch.minOrder);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const handleVariantSelect = (variantId, variantName, variantPrice, variantAmount) => {
    setSelectedVariants((prevSelected) => {
      const variantIndex = prevSelected.findIndex(variant => variant.variantId === variantId);
  
      if (variantIndex !== -1) {
        const updatedSelected = [...prevSelected];
        updatedSelected.splice(variantIndex, 1);
        return updatedSelected;
      } else {
        return [...prevSelected, { variantId, variantName, variantPrice, variantAmount }];
      }
    });
  };
  

  const dispatch = useDispatch();

  const isVariantSelected = (variant) => {
    return selectedVariants.some((selectedVariant) => (
      selectedVariant.variantId === variant.variantId
    ));
  };

  return (
    <div className="modal">
      <div onClick={handleClose} className="modal-close-overlay"></div>
      <div className="modal-content">
        <button onClick={handleClose} className="modal-close-btn">
          <ion-icon name="close-outline"></ion-icon>
        </button>
        <div className="newsletter-img">
          <img
            src={process.env.REACT_APP_API_URL + `images/${product.image}`}
            alt="subscribe newsletter"
            width="400"
            height="400"
          />
        </div>
        <div className="modal-infos">
          <span className="modalTitle">{product.productName}</span>
          <span className="modalDesc">{product.description}</span>
          <hr />
          <div className="variants">
            {product.variants[0] !== null &&
              Object.values(
                product.variants.reduce((acc, variant) => {
                  if (!acc[variant.variantGroupName]) {
                    acc[variant.variantGroupName] = [];
                  }
                  acc[variant.variantGroupName].push(variant);
                  return acc;
                }, {})
              ).map((groupVariants) => (
                <div key={groupVariants[0].variantGroupName} >
                  <span className="variantGroup">{groupVariants[0].variantGroupName}</span>
                  {groupVariants.map((each) => (
                <div className="variantList" key={each.variantId} onClick={() => {
                  handleVariantSelect(
                    each.variantId,
                    each.variantName,
                    each.variantPrice,
                    1
                  );
                }}>
                      <div className="variantName">{each.variantName}</div>
                      <div className="variantPrice">{each.variantPrice} €</div>
                      <div className="variantNote">{each.variantNote}</div>
                      <ion-icon
                        name={isVariantSelected(each) ? 'checkmark-circle-outline' : 'add-outline'}
                        className="add-icon"
                       
                      ></ion-icon>
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <button
            onClick={() => {
              dispatch(
                addToCard({
                  id: product.productId,
                  image: product.image,
                  price: product.price,
                  discount: product.discount,
                  productName: product.productName,
                  quantity: 1,
                  branchName: branchName,
                  branchId: branchId,
                  postCode: postCode,
                  deliveryFee: deliveryFee,
                  minOrder: minOrder,
                  variants: selectedVariants,
                })
              );
              handleClose();
            }}
            className="btn-newsletter"
          >
            Add to basket
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
