import { apiSlice } from "./apiSlice"
export const postcodeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllPostCodes: builder.mutation({
            query: () => ({
                url: '/postcodes/0',
                method: 'GET',
            }),
        }),
      
     
})
})
export const {
useGetAllPostCodesMutation
} = postcodeApiSlice 