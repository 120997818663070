import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { addToCard, removeFromCard,removeVariantFromCard,addVariantToCard } from '../redux/cartReducer'

const Basket = ({type}) => {
    
    const basketItems = useSelector(state=>state.cart.products)
    const deliveryFee = useSelector(state=>state.cart.deliveryFee)
    const minOrder = useSelector(state=>state.cart.minOrder)
    const total = useSelector(state=>state.cart.total)
    const dispatch = useDispatch()
  return (
    <div className="sidebar has-scrollbar" data-mobile-menu>

    <div className="sidebar-category">

      <div className="sidebar-top">
        <h2 className="sidebar-title">Basket</h2>

        <button className="sidebar-close-btn" data-mobile-menu-close-btn>
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>

      <ul className="sidebar-menu-category-list">

        <div className="product-showcase">
          <div className="showcase-wrapper">

            <div className="showcase-container">
            {
              basketItems.map(item=>(
                <div>
                  <div className="showcase" key={item.id}>

                    <a href="#" className="showcase-img-box">
                      <img src={process.env.REACT_APP_API_URL+`images/${item.image}`}  alt={item.productName} width="50" height="50"
                        className="showcase-img"/>
                    </a>

                    <div className="showcase-content">

                      
                        <h4 className="showcase-title">{item.productName}</h4>
                   

                  

                      <div className="price-box">
                      {item.discount !== 0 && <del>{Math.floor(( item.price * 1/item.discount) + item.price).toFixed(2)} €</del>}
                        <p className="price">{item.price} € x {item.quantity} = {(item.price * item.quantity).toFixed(2)} €</p>
                      </div>
                      

                    </div>
                    <div className="price-box">
                
                    {type === "branch" && 
                    <button onClick={()=>dispatch(removeFromCard({id:item.id,variants:item.variants}))}>
                    <ion-icon name="remove-outline" className="remove-icon"></ion-icon>
                    </button>
                    }
                      <span className="price">x</span>
                      <span className="price">{item.quantity}</span>
                      {type === "branch" && 
                    <button onClick={()=>dispatch(addToCard({
                    id:item.id,
                    variants:item.variants
                  }))}>
                    <ion-icon name="add-outline" className="add-icon"></ion-icon>
                    </button> }
                  
                    </div>
                  
              
                  </div>
                  {item.variants.length > 0 && (
                  <div className='basketVariants'>
                      <span className='basketVariantTitle'>Variants</span>
                   {item.variants.map(variant =>(
                    <div className="basketVariantsGroup">
                    <div className="showcase-title">{variant.variantName} ({variant.variantPrice.toFixed(2)} € ) </div>
                    <div className='price-box'>
                    {type === "branch" && 
                    <button onClick={() => dispatch(removeVariantFromCard({ id: item.id, variantId: variant.variantId,variants:item.variants }))}>
                      <ion-icon name="remove-outline" className="remove-icon"></ion-icon>
                    </button> 
                    }
                    <span>x</span>
                    <div className="basketVariantAmount">{variant.variantAmount} </div>
                    {type === "branch" && 
                    <button onClick={() => dispatch(addVariantToCard({ id: item.id, variantId: variant.variantId,variants:item.variants }))}>
                      <ion-icon name="add-outline" className="add-icon"></ion-icon>
                    </button>
                    }
                  </div>
                  </div>

                   ))}
                   
                  </div>
                )}
                  <hr></hr>
                </div>
                
              ))
              
              }
              
            </div>
          
          </div>
        
        </div> 
      
      </ul>
    
      { type === "bezahlen" && 
      <div>
        <div className="price-box">
        <span className="showcase-title">Delivery Fee: </span>
        <span className="price">{deliveryFee.toFixed(2)} €</span>
        </div>
        <br/>
      </div>
      }
     
      {
        basketItems.length > 0 &&  type === "bezahlen" &&
        <div>
        <div style={{display:"flex"}}>
        <h2 style={{color:"grey",fontSize:"16px"}}>Total</h2>
        <div style={{marginLeft:"8px",fontSize:"16px",fontWeight:"bold"}}>{total + deliveryFee} €</div>
        </div>
      
        </div>
        
      }
      {type === "branch" && minOrder <= total && <Link to="/bezahlen" className="banner-btn"><span>Go to payment</span></Link>}
    </div>


  </div>
  )
}

export default Basket
