import React from 'react'

const Banner2 = () => {
  return (
    <div className='banner2-container'>
        <div className='banner2-content'>

        <p>Wir sind in Berlin</p>

        <h2>Wedding</h2>
        <h2>Charlottenburg</h2>
        <h2>Neukölln</h2>
        <h2>Friedenau</h2>
        <h2>Spandau</h2>
        </div>
        <img src="/images/icons/right-arrow.png" alt="banner1" className='banner2-img'/>
        <img src="/images/icons/payment.png" alt="banner1" className='banner2-img'/>
        <img src="/images/icons/right-arrow.png" alt="banner1" className='banner2-img'/>
        <img src="/images/icons/shipping.png" alt="banner1" className='banner2-img'/>
    </div>
  )
}

export default Banner2
