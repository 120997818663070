import React from 'react'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Category from '../components/Category'
import Product from '../components/Product'
import Header from '../components/Header'
const Branch = () => {

  return (
    <div>
      <Header type="branch"/>
      <Banner/> 
      <Category/>
      <Product/>
      <Footer/>
    </div>
  )
}

export default Branch
