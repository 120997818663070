import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Basket from '../components/Basket'
import axios from 'axios';
import "./bezahlen.css"
import {  useSelector } from 'react-redux';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddOrderMutation } from '../api/orderApiSlice';
const Bezahlen = () => {
  const navigate = useNavigate();
  const notify = (error) => toast(error);
  const branchId = useSelector(state=>state.branch.branch.branchId)
  const basketItems = useSelector(state=>state.cart.products)
  const branchPostCode = basketItems[0].postCode
  const authUser = useSelector(state=>state.auth.user)
  const [orderForm,setOrderForm] = useState()
  const handleChange = (e) => {
    setOrderForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    const [addOrder] = useAddOrderMutation()
  const handleOrder = async(e) =>{
    e.preventDefault();
    try {
      await addOrder({name:orderForm.name,surname:orderForm.surname,street:orderForm.street,postCode:branchPostCode,city:orderForm.city,country:orderForm.country,floor:orderForm.floor,telNo:orderForm.telNo,customerId: authUser ? JSON.parse(authUser).id : 0,date:Date.now(),requestedTime:orderForm.requestedTime ? orderForm.requestedTime : "-",note:orderForm.note ? orderForm.note : "-",branchId:branchId,products:JSON.stringify(basketItems)})
      navigate(`paymentform`)
      } catch (error) {
        notify(error)
      }
    }

  return (
    <div>
      {/* <div className="header-main">
        <div className="container">
        <Link to={`/${branchName}`} className="header-logo">
            <div>{"< "}ElbeOrder </div>
          </Link>
          <div style={{color:"black",fontWeight:"bold"}}>Kasse</div>  
          <div className="header-top-actions">
            <select name="language">
              <option value="en-US">English</option>
              <option value="es-ES">Deutsch</option>
            </select>
          </div>
        </div>
      </div> */}
    <Header type="branch"/>
    <div className="product-container">
      <div className="container">
        <Basket type="bezahlen"/>
         <form className='bezahlen-form' onSubmit={handleOrder}>
         <div className="formInput">
                  <label>Name(*)</label>
                  <input name="name" required onChange={handleChange} type="text" placeholder="Type Name" />
        </div>

        <div className="formInput">
                  <label>Surname(*)</label>
                  <input name="surname" required onChange={handleChange} type="text" placeholder="Type Surname" />
        </div>
        <div className="formInput">
                  <label>Street(*)</label>
                  <input name="street" required onChange={handleChange} type="text" placeholder="Type Street" />
        </div>
        <div className="formInput">
                  <label>Post Code(*)</label>
                  <input name="postCode" value={branchPostCode} type="number" readOnly/>
        </div>
        <div className="formInput">
                  <label>City</label>
                  <input name="city" onChange={handleChange} type="text" placeholder="Type City" />
        </div>
        <div className="formInput">
                  <label>Country</label>
                  <input name="country" onChange={handleChange} type="text" placeholder="Type Country" />
        </div>
        <div className="formInput">
                  <label>Floor(*)</label>
                  <input name="floor" required onChange={handleChange} type="number" placeholder="Type Street" />
        </div>
        <div className="formInput">
  <label>Phone Number(*)</label>
  <input 
    name="telNo" 
    required 
    onChange={handleChange}
    type="tel" 
    pattern="[0-9]{11}"
    placeholder="01231231212" 
  />
</div>
        <div className="formInput">
                  <label>Requested Time</label>
                  <input name="requestedTime" onChange={handleChange} type="time" />
        </div>
        <div className="formInput">
                  <label>Note</label>
                  <input name="note" onChange={handleChange} type="text"  placeholder="Type Note" />
        </div>
        {/* <StripeContainer /> */}
        <button  className="pay-button">Go To Payment</button>
        <ToastContainer />
  </form> 
     
      </div>
   
    </div>
    <Footer/>
    </div>

  )
}

export default Bezahlen
