import React from 'react'

const AboutBanner = () => {
  return (
    <div className='aboutus'>
      <h1>About Us</h1>
    <p>Welcome to ElbeOrder, your go-to order application for all your needs!</p>
    
    <h2>Our Mission</h2>
    <p>Our mission is to provide a seamless and convenient platform for users to place their orders easily and efficiently.</p>
    
    <h2>What We Offer</h2>
    <p>With ElbeOrder, you can browse through a wide range of products and services, choose your preferred options, and place your order within seconds. We offer a user-friendly interface, secure payment processing, and timely delivery to ensure a smooth and satisfactory experience for our customers.</p>
    
    <h2>Why Choose Us</h2>
    <ul>
        <li>Convenience: Our application is designed to make the ordering process as simple and effortless as possible.</li>
        <li>Variety: We offer a diverse selection of products and services to cater to different preferences and tastes.</li>
        <li>Reliability: We work with trusted vendors and service providers to ensure that your orders are fulfilled accurately and on time.</li>
        <li>Security: Your personal and payment information is safeguarded with industry-standard encryption and security protocols.</li>
        <li>Customer Satisfaction: We strive to provide excellent customer service and address any queries or concerns promptly.</li>
    </ul>
    
    <h2>Contact Us</h2>
    <p>If you have any questions, feedback, or inquiries, please feel free to get in touch with us:</p>
    
    <ul>
        <li>Email: info@elbeorder.com</li>
        <li>Phone: +1 123-456-7890</li>
        <li>Address: 123 Example Street, City, Country</li>
    </ul>
    </div>
  )
}

export default AboutBanner
