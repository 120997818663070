import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
        headers.set("x-api-key",process.env.REACT_APP_API_TOKEN)
        return headers
    }
})


export const apiSlice = createApi({
    baseQuery: baseQuery,
    endpoints: builder => ({})
})