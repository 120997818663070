import Branch from "./pages/Branch";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Bezahlen from "./pages/Bezahlen";
import { useSelector } from "react-redux";
import Profile from "./pages/Profile";
import StripeContainer from "./components/stripe/StripeContainer";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Completion from "./components/stripe/Completion";
      
function App() {
  const authUser = useSelector(state=>state.auth.user)
  const basketItems = useSelector(state=>state.cart.products)
  const total = useSelector(state=>state.cart.total)
  const minOrder = useSelector(state=>state.cart.minOrder)
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home/>}/>
        <Route path=":branchName" element={<Branch/>}/>
        <Route path="aboutus" element={<AboutUs/>}/>
        <Route path="contact" element={<Contact/>}/>
        <Route path="bezahlen">
          <Route index element={basketItems.length > 0 && total >= minOrder ?<Bezahlen/> : <Home/>}/>
          <Route path="paymentform" element={basketItems.length > 0 && total >= minOrder ? <StripeContainer/>  : <Home/> } />
          <Route path="completion" element={<Completion />} />
        </Route> 
        <Route path="register" element={authUser ? <Home/> : <Register/>}/>
        <Route path="login" element={authUser ? <Home/> :<Login/>}/>
        <Route path="profile" element={authUser ? <Profile/> : <Login/> }/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
