
import cartReducer from './cartReducer'

import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './authReducer'
import branchReducer from './branchReducer'
import { apiSlice } from '../api/apiSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const persistCardConfig = {
  key: 'card',
  version: 1,
  storage,
}

const persistAuthConfig = {
  key: 'auth',
  version: 1,
  storage,
}
const persistBranchConfig = {
  key: 'branch',
  version: 1,
  storage,
}


const persistedReducer = persistReducer(persistCardConfig, cartReducer)
const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer)
const persistedBranchReducer = persistReducer(persistBranchConfig, branchReducer)
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    cart:persistedReducer,
    auth:persistedAuthReducer,
    branch:persistedBranchReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
})
setupListeners(store.dispatch)
export let persistor = persistStore(store)
