import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios'
import { useAddCustomerMutation } from '../api/customerApiSlice'

const Register = () => {
  const [user,setUser] = useState()
  const [error,setError] = useState(false)
  const navigate = useNavigate();
  const handleChange = (e)=>{
    setUser((prev)=>({...prev,[e.target.name]:e.target.value}))
  }
  const [addCustomer] = useAddCustomerMutation()
  const handleRegister = async(e)=>{
    e.preventDefault()
    try {
        await addCustomer({name:user.name,surname:user.surname,email:user.email,username:user.username,password:user.password})
        navigate("/login")
      } catch (error) {
        setError(true)
      }
    }
  return (
    <div>
    <Header/>
   
  <form className='login-form'>
 
    <div className="formInput">
      <span>Name</span>
      <input name='name' placeholder='John' onChange={handleChange} type='text'></input>
    </div>
    <div className="formInput">
      <span>Surname</span>
      <input name='surname'placeholder='Doe' onChange={handleChange} type='text'></input>
    </div>
    <div className="formInput">
      <span>Email</span>
      <input name='email' placeholder='john@gmail.com' onChange={handleChange} type="email"></input>
    </div>
    <div className="formInput">
      <span>Username</span>
      <input name='username' placeholder='john36' onChange={handleChange} type='text'></input>
    </div>
    <div className="formInput">
      <span>Password</span>
      <input name='password' placeholder='Please type a password!' onChange={handleChange} type='password'></input>
    </div>
    <button className="pay-button" onClick={handleRegister}>Register</button>
    {error && <div>Error happened.</div>}
  <Link className='register' to={"/login"}>
    <span>Go to Login</span>
  </Link>
  </form>
    
    <Footer/>
    </div>
   
  )
}

export default Register
