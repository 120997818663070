import { apiSlice } from "./apiSlice"

export const customerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addCustomer:builder.mutation({
            query: formData => ({
                url: '/users',
                method: 'POST',
                body:formData
            }),
        }),
    })
})

export const {
useAddCustomerMutation
} = customerApiSlice 