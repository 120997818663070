import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginStart } from '../redux/authReducer'
import { Link, useNavigate } from 'react-router-dom'
import "./profile.css"
import Header from '../components/Header'
import Footer from '../components/Footer'
const Profile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const authUser = JSON.parse(useSelector(state=>state.auth.user))
    const [user,setUser] = useState(authUser)
    const handleChange = (e)=>{
      setUser((prev)=>({...prev,[e.target.name]:e.target.value}))
    }
  return (
    <div>
    <Header type="profile"/>
      <div className='profileContainer'>
      <div className='profileItem'> 
        <span>Name:</span>
        <input onChange={handleChange} value={user.name} name='name'/>
      </div>
      <div className='profileItem'>
        <span>Surname:</span>
        <input onChange={handleChange} value= {user.surname} name="surname"/>
      </div>
      <div className='profileItem'>
        <span>Email:</span>
        <input onChange={handleChange} value= {user.email} name="email"/>
      </div>
      <div className='profileItem'>
        <span>Username:</span>
        <input onChange={handleChange} value= {user.username} name="username"/>
      </div> 
      <div className='profileItem'>
        <span>Street:</span>
        <input onChange={handleChange} value= {user.street} name="street"/>
      </div>
      <div className='profileItem'>
        <span>Floor:</span>
        <input onChange={handleChange} value={user.floor} name="floor"/>
      </div>
      <div className='profileItem'>
        <span>Post Code:</span>
        <input onChange={handleChange} value= {user.postCode} name="postCode"/>
      </div>
      <div className='profileItem'>
        <span>Company Name:</span>
        <input onChange={handleChange} value={user.companyName} name="companyName"/>
      </div>
      <div className='profileItem'>
        <span>City:</span>
        <input onChange={handleChange} value={user.city} name="city"/>
      </div>
      <div className='profileItem'>
        <span>Country:</span>
        <input onChange={handleChange} value={user.country} name="country"/>
      </div>
      <div className='profileItem'>
        <span>Phone No:</span>
        <input onChange={handleChange} type='number' value= {user.telNo} name="telNo" />
      </div>
      {user.isSecure === 0 ?
      <div className='profileItem'>
        <span>Not Verified</span>
      </div> : 
       <div className='profileItem'>
       <span>Verified</span>
     </div>
    
      }
       <div className='buttons'>
       <button className='save-btn' onClick={()=>{
       
      }}>Save Changes</button>
        <button className='logout-btn' onClick={()=>{
        dispatch(loginStart())
        navigate("/")
      }}>Logout</button>
      </div>
  
      </div>
      
    
     
     
    <Footer/>
    </div>
    
  )
}

export default Profile
